
import { EstimationClient, FabricEstimationClient, GarmentDefaultDataClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import store from '@/store';

@Options({})
export default class Layout extends Vue {

    canNavigate: boolean = false;
    hasFarming: boolean = false;
    hasWool: boolean = false;
    loaded: boolean = false;

    created(){
        var saveToGarmentConfiguration = !!this.$route.meta.saveToGarmentConfiguration;

        if(saveToGarmentConfiguration)
            store.state.estimationClient = GarmentDefaultDataClient;
        else
            store.state.estimationClient = EstimationClient;

        this.getProductionPhases();
        this.checkIfNavbarIsNavigable();
    }

    @Watch('$store.state.estimationIdentifier')
    checkIfNavbarIsNavigable() {
        if(store.state.estimationIdentifier) {
            EstimationClient.getIfFinished(store.state.estimationIdentifier)
            .then(x => {
                this.canNavigate = x;
            })
        }
    }

    navigateTo(route) {
        if(!this.canNavigate)
            return;

        this.$router.push(route);
    }

    @Watch('$route.name', { deep: true })
    getProductionPhases() {
        var estimationIdentifier = this.$route.params.estimationIdentifier ? this.$route.params.estimationIdentifier.toString() : "";
        if(estimationIdentifier != "") 
        {
            store.state.estimationIdentifier = estimationIdentifier;
            Promise.all([
                EstimationClient.getUpdatedProductionPhases(estimationIdentifier),
                FabricEstimationClient.checkIfHasFarming(estimationIdentifier),
                FabricEstimationClient.checkIfHasWool(estimationIdentifier)
            ])
            .then(xs => {
                this.$store.state.phases = xs[0];
                this.hasFarming = xs[1];
                this.hasWool = xs[2];
                this.loaded = true;
            })
        }
        else
            this.loaded = true;
    }

    get activeStep(){
        if(this.$route.name == "fabric farming" || this.$route.meta.name == "fabric farming")
            return 1;
        else if(this.$route.name == "fabric wool" || this.$route.meta.name == "fabric wool")
            return 2;
        else if(this.$route.name == "fabric raw materials" || this.$route.meta.name == "fabric raw materials")
            return 3;
        else if(this.$route.name == "fabric production process" || this.$route.meta.name == "fabric production process")
        {
            var process = this.$route.params.process.toString();
            var found = this.$store.state.phases.find( x => x.text == process);
            var index = this.$store.state.phases.indexOf(found);
            return index + 4;
        }
        else if(this.$route.name == "fabric final transportation" || this.$route.meta.name == "fabric final transportation")
            return this.$store.state.phases.length + 4;
        else if(this.$route.name == "fabric summary")
            return this.$store.state.phases.length + 5;

        return 0;
    }
}
